import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Timestamp: { input: any; output: any; }
};

export type AllowedGeographicalArea = {
  __typename?: 'AllowedGeographicalArea';
  activatedAt: Scalars['Timestamp']['output'];
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  radiusMeters?: Maybe<Scalars['Int']['output']>;
};

export type AllowedGeographicalAreasForAdminFilterInput = {
  countryCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AllowedGeographicalAreasForAdminResponse = {
  __typename?: 'AllowedGeographicalAreasForAdminResponse';
  edges: Array<AllowedGeographicalArea>;
  pageInfo: RetoolPageInfo;
};

export type CreateAllowedGeographicalAreaByCountryInput = {
  countryCode: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAllowedGeographicalAreaByGeoPointInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  radiusMeters: Scalars['Int']['input'];
};

export type CreateGeoLocationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type CreateWaitlistUserInput = {
  geoLocation: CreateGeoLocationInput;
  userData: WaitlistUserInput;
};

export type DeeplinkUrl = {
  __typename?: 'DeeplinkUrl';
  url: Scalars['String']['output'];
};

export type FinishProfileNetworkSocialInput = {
  email: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type FinishProfileNetworkWalletInput = {
  email: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type FinishProfilePlatformSocialInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  profileImageUrl: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type FinishProfilePlatformWalletInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  profileImageUrl: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  waitlistUser: WaitlistUser;
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['Timestamp']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  waitlistUser: Array<WaitlistUser>;
};

export enum LoginType {
  Google = 'Google',
  Metamask = 'Metamask',
  Passwordless = 'Passwordless',
  Twitter = 'Twitter'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAllowedGeographicalAreaByCountry: AllowedGeographicalArea;
  createAllowedGeographicalAreaByGeoPoint: AllowedGeographicalArea;
  createWaitlistUser: WaitlistUser;
  deleteAllowedGeographicalArea: Scalars['Boolean']['output'];
  finishProfileNetworkSocial: User;
  finishProfileNetworkWallet: User;
  finishProfilePlatformSocial: User;
  finishProfilePlatformWallet: User;
  incrementInvitesCount: Scalars['Boolean']['output'];
  updateAllowedGeographicalArea: AllowedGeographicalArea;
  updateDefaultInvitesCount: Scalars['Int']['output'];
  updateProfileNetwork: User;
  updateProfilePlatform: User;
};


export type MutationCreateAllowedGeographicalAreaByCountryArgs = {
  input: CreateAllowedGeographicalAreaByCountryInput;
};


export type MutationCreateAllowedGeographicalAreaByGeoPointArgs = {
  input: CreateAllowedGeographicalAreaByGeoPointInput;
};


export type MutationCreateWaitlistUserArgs = {
  input: CreateWaitlistUserInput;
};


export type MutationDeleteAllowedGeographicalAreaArgs = {
  allowedGeographicalAreaId: Scalars['String']['input'];
};


export type MutationFinishProfileNetworkSocialArgs = {
  input: FinishProfileNetworkSocialInput;
};


export type MutationFinishProfileNetworkWalletArgs = {
  input: FinishProfileNetworkWalletInput;
};


export type MutationFinishProfilePlatformSocialArgs = {
  input: FinishProfilePlatformSocialInput;
};


export type MutationFinishProfilePlatformWalletArgs = {
  input: FinishProfilePlatformWalletInput;
};


export type MutationIncrementInvitesCountArgs = {
  input: UpdateInvitesCountInput;
};


export type MutationUpdateAllowedGeographicalAreaArgs = {
  allowedGeographicalAreaId: Scalars['String']['input'];
  input: UpdateAllowedGeographicalAreaInput;
};


export type MutationUpdateDefaultInvitesCountArgs = {
  count: Scalars['Int']['input'];
};


export type MutationUpdateProfileNetworkArgs = {
  input: UpdateProfileNetworkInput;
};


export type MutationUpdateProfilePlatformArgs = {
  input: UpdateProfilePlatformInput;
};

export type NetworkUser = {
  __typename?: 'NetworkUser';
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  referralUrl: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  user: User;
};

export type NodeInfo = {
  __typename?: 'NodeInfo';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  countryId: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
  ip: Scalars['String']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  maxNodeClients: Scalars['Int']['output'];
  maxRoomClients: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numip: Scalars['Int']['output'];
  os: Scalars['String']['output'];
  perm: Scalars['Int']['output'];
  port: Scalars['Int']['output'];
  version: Scalars['String']['output'];
  versionSort: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type PlatformUser = {
  __typename?: 'PlatformUser';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  invitesUsedCount: Scalars['Int']['output'];
  totalInvitesCount: Scalars['Int']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  user: User;
};

export type Query = {
  __typename?: 'Query';
  allowedGeographicalAreasForAdmin: AllowedGeographicalAreasForAdminResponse;
  defaultInvitesCount: Scalars['Int']['output'];
  getCurrentNetworkUser: NetworkUser;
  loginDeeplink: DeeplinkUrl;
  myAvailableInvitesCount: Scalars['Int']['output'];
  roomDeeplink: DeeplinkUrl;
  rooms: Array<Room>;
  userMe: User;
  usersForAdmin: UsersForAdminResponse;
  usersForDesktopApp: Array<UserForDesktopApp>;
  waitlistUsersForAdmin: WaitlistUsersForAdminResponse;
};


export type QueryAllowedGeographicalAreasForAdminArgs = {
  filter?: InputMaybe<AllowedGeographicalAreasForAdminFilterInput>;
  pagination: RetoolPaginationInput;
};


export type QueryRoomDeeplinkArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryUsersForAdminArgs = {
  filter?: InputMaybe<UsersForAdminFilterInput>;
  pagination: RetoolPaginationInput;
};


export type QueryUsersForDesktopAppArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryWaitlistUsersForAdminArgs = {
  filter?: InputMaybe<WaitlistUsersForAdminFilterInput>;
  pagination: RetoolPaginationInput;
};

export type RetoolPageInfo = {
  __typename?: 'RetoolPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  totalEdges: Scalars['Int']['output'];
};

export type RetoolPaginationInput = {
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};

export enum Role {
  Network = 'Network',
  Platform = 'Platform'
}

export type Room = {
  __typename?: 'Room';
  audioPort: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['Int']['output'];
  nodeInfo: NodeInfo;
  numClients: Scalars['Int']['output'];
  pingPort: Scalars['Int']['output'];
  port: Scalars['Int']['output'];
  roomId: Scalars['Int']['output'];
  status: Status;
  streamingUrl: Scalars['String']['output'];
  visibility: Visibility;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type UpdateAllowedGeographicalAreaInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  radiusMeters?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInvitesCountInput = {
  count: Scalars['Int']['input'];
  platformUserIds: Array<Scalars['String']['input']>;
  type: UpdateInvitesCountInputType;
};

export enum UpdateInvitesCountInputType {
  All = 'All',
  Granular = 'Granular'
}

export type UpdateProfileNetworkInput = {
  userName: Scalars['String']['input'];
};

export type UpdateProfilePlatformInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isProfileComplete: Scalars['Boolean']['output'];
  loginType: LoginType;
  networkUser?: Maybe<NetworkUser>;
  platformUser?: Maybe<PlatformUser>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
  updatedAt: Scalars['Timestamp']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type UserForDesktopApp = {
  __typename?: 'UserForDesktopApp';
  countryCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type UsersForAdminFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type UsersForAdminResponse = {
  __typename?: 'UsersForAdminResponse';
  edges: Array<User>;
  pageInfo: RetoolPageInfo;
};

export enum Visibility {
  Private = 'Private',
  Public = 'Public'
}

export type WaitlistUser = {
  __typename?: 'WaitlistUser';
  createdAt: Scalars['Timestamp']['output'];
  email: Scalars['String']['output'];
  geoLocation?: Maybe<GeoLocation>;
  geoLocationId: Scalars['String']['output'];
  locationUserInput: Scalars['String']['output'];
};

export type WaitlistUserInput = {
  email: Scalars['String']['input'];
  locationUserInput: Scalars['String']['input'];
};

export type WaitlistUsersForAdminFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  userInput?: InputMaybe<Scalars['String']['input']>;
};

export type WaitlistUsersForAdminResponse = {
  __typename?: 'WaitlistUsersForAdminResponse';
  edges: Array<WaitlistUser>;
  pageInfo: RetoolPageInfo;
};

export enum Web3AuthIdentifierType {
  EthereumWalletAddress = 'EthereumWalletAddress',
  PublicKey = 'PublicKey'
}

export type FinishProfileNetworkSocialMutationVariables = Exact<{
  input: FinishProfileNetworkSocialInput;
}>;


export type FinishProfileNetworkSocialMutation = { __typename?: 'Mutation', finishProfileNetworkSocial: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type FinishProfileNetworkWalletMutationVariables = Exact<{
  input: FinishProfileNetworkWalletInput;
}>;


export type FinishProfileNetworkWalletMutation = { __typename?: 'Mutation', finishProfileNetworkWallet: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type UpdateProfileNetworkMutationVariables = Exact<{
  input: UpdateProfileNetworkInput;
}>;


export type UpdateProfileNetworkMutation = { __typename?: 'Mutation', updateProfileNetwork: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type GetCurrentNetworkUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentNetworkUserQuery = { __typename?: 'Query', getCurrentNetworkUser: { __typename?: 'NetworkUser', id: string, referralUrl: string } };

export type GetMyAvailableInvitesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAvailableInvitesCountQuery = { __typename?: 'Query', myAvailableInvitesCount: number };

export type FinishProfilePlatformSocialMutationVariables = Exact<{
  input: FinishProfilePlatformSocialInput;
}>;


export type FinishProfilePlatformSocialMutation = { __typename?: 'Mutation', finishProfilePlatformSocial: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type FinishProfilePlatformWalletMutationVariables = Exact<{
  input: FinishProfilePlatformWalletInput;
}>;


export type FinishProfilePlatformWalletMutation = { __typename?: 'Mutation', finishProfilePlatformWallet: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type UpdateProfilePlatformMutationVariables = Exact<{
  input: UpdateProfilePlatformInput;
}>;


export type UpdateProfilePlatformMutation = { __typename?: 'Mutation', updateProfilePlatform: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type RoomDetailFragment = { __typename?: 'Room', roomId: number, nodeId: number, visibility: Visibility, status: Status, name: string, streamingUrl: string, pingPort: number, numClients: number, nodeInfo: { __typename?: 'NodeInfo', walletAddress: string, maxNodeClients: number, maxRoomClients: number, country: string, countryId: number, city: string, ip: string, countryCode: string } };

export type GetRoomsForPlatformQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoomsForPlatformQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', roomId: number, nodeId: number, visibility: Visibility, status: Status, name: string, streamingUrl: string, pingPort: number, numClients: number, nodeInfo: { __typename?: 'NodeInfo', walletAddress: string, maxNodeClients: number, maxRoomClients: number, country: string, countryId: number, city: string, ip: string, countryCode: string } }> };

export type GetRoomDeeplinkQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
}>;


export type GetRoomDeeplinkQuery = { __typename?: 'Query', roomDeeplink: { __typename?: 'DeeplinkUrl', url: string } };

export type UserMeFragment = { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null };

export type GetUserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserMeQuery = { __typename?: 'Query', userMe: { __typename?: 'User', id: string, isProfileComplete: boolean, roles: Array<Role>, email?: string | null, profileImageUrl?: string | null, userName?: string | null, loginType: LoginType, countryCode?: string | null } };

export type GetLoginDeeplinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoginDeeplinkQuery = { __typename?: 'Query', loginDeeplink: { __typename?: 'DeeplinkUrl', url: string } };

export type CreateWaitlistUserMutationVariables = Exact<{
  input: CreateWaitlistUserInput;
}>;


export type CreateWaitlistUserMutation = { __typename?: 'Mutation', createWaitlistUser: { __typename?: 'WaitlistUser', email: string, locationUserInput: string, createdAt: any } };

export const RoomDetailFragmentDoc = gql`
    fragment RoomDetail on Room {
  roomId
  nodeId
  visibility
  status
  name
  streamingUrl
  pingPort
  numClients
  nodeInfo {
    walletAddress
    maxNodeClients
    maxRoomClients
    country
    countryId
    city
    ip
    countryCode
  }
}
    `;
export const UserMeFragmentDoc = gql`
    fragment UserMe on User {
  id
  isProfileComplete
  roles
  email
  profileImageUrl
  userName
  loginType
  countryCode
}
    `;
export const FinishProfileNetworkSocialDocument = gql`
    mutation FinishProfileNetworkSocial($input: FinishProfileNetworkSocialInput!) {
  finishProfileNetworkSocial(input: $input) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
export type FinishProfileNetworkSocialMutationFn = Apollo.MutationFunction<FinishProfileNetworkSocialMutation, FinishProfileNetworkSocialMutationVariables>;

/**
 * __useFinishProfileNetworkSocialMutation__
 *
 * To run a mutation, you first call `useFinishProfileNetworkSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProfileNetworkSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProfileNetworkSocialMutation, { data, loading, error }] = useFinishProfileNetworkSocialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishProfileNetworkSocialMutation(baseOptions?: Apollo.MutationHookOptions<FinishProfileNetworkSocialMutation, FinishProfileNetworkSocialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishProfileNetworkSocialMutation, FinishProfileNetworkSocialMutationVariables>(FinishProfileNetworkSocialDocument, options);
      }
export type FinishProfileNetworkSocialMutationHookResult = ReturnType<typeof useFinishProfileNetworkSocialMutation>;
export type FinishProfileNetworkSocialMutationResult = Apollo.MutationResult<FinishProfileNetworkSocialMutation>;
export type FinishProfileNetworkSocialMutationOptions = Apollo.BaseMutationOptions<FinishProfileNetworkSocialMutation, FinishProfileNetworkSocialMutationVariables>;
export const FinishProfileNetworkWalletDocument = gql`
    mutation FinishProfileNetworkWallet($input: FinishProfileNetworkWalletInput!) {
  finishProfileNetworkWallet(input: $input) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
export type FinishProfileNetworkWalletMutationFn = Apollo.MutationFunction<FinishProfileNetworkWalletMutation, FinishProfileNetworkWalletMutationVariables>;

/**
 * __useFinishProfileNetworkWalletMutation__
 *
 * To run a mutation, you first call `useFinishProfileNetworkWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProfileNetworkWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProfileNetworkWalletMutation, { data, loading, error }] = useFinishProfileNetworkWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishProfileNetworkWalletMutation(baseOptions?: Apollo.MutationHookOptions<FinishProfileNetworkWalletMutation, FinishProfileNetworkWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishProfileNetworkWalletMutation, FinishProfileNetworkWalletMutationVariables>(FinishProfileNetworkWalletDocument, options);
      }
export type FinishProfileNetworkWalletMutationHookResult = ReturnType<typeof useFinishProfileNetworkWalletMutation>;
export type FinishProfileNetworkWalletMutationResult = Apollo.MutationResult<FinishProfileNetworkWalletMutation>;
export type FinishProfileNetworkWalletMutationOptions = Apollo.BaseMutationOptions<FinishProfileNetworkWalletMutation, FinishProfileNetworkWalletMutationVariables>;
export const UpdateProfileNetworkDocument = gql`
    mutation UpdateProfileNetwork($input: UpdateProfileNetworkInput!) {
  updateProfileNetwork(input: $input) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
export type UpdateProfileNetworkMutationFn = Apollo.MutationFunction<UpdateProfileNetworkMutation, UpdateProfileNetworkMutationVariables>;

/**
 * __useUpdateProfileNetworkMutation__
 *
 * To run a mutation, you first call `useUpdateProfileNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileNetworkMutation, { data, loading, error }] = useUpdateProfileNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileNetworkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileNetworkMutation, UpdateProfileNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileNetworkMutation, UpdateProfileNetworkMutationVariables>(UpdateProfileNetworkDocument, options);
      }
export type UpdateProfileNetworkMutationHookResult = ReturnType<typeof useUpdateProfileNetworkMutation>;
export type UpdateProfileNetworkMutationResult = Apollo.MutationResult<UpdateProfileNetworkMutation>;
export type UpdateProfileNetworkMutationOptions = Apollo.BaseMutationOptions<UpdateProfileNetworkMutation, UpdateProfileNetworkMutationVariables>;
export const GetCurrentNetworkUserDocument = gql`
    query GetCurrentNetworkUser {
  getCurrentNetworkUser {
    id
    referralUrl
  }
}
    `;

/**
 * __useGetCurrentNetworkUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentNetworkUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentNetworkUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentNetworkUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentNetworkUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>(GetCurrentNetworkUserDocument, options);
      }
export function useGetCurrentNetworkUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>(GetCurrentNetworkUserDocument, options);
        }
export function useGetCurrentNetworkUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>(GetCurrentNetworkUserDocument, options);
        }
export type GetCurrentNetworkUserQueryHookResult = ReturnType<typeof useGetCurrentNetworkUserQuery>;
export type GetCurrentNetworkUserLazyQueryHookResult = ReturnType<typeof useGetCurrentNetworkUserLazyQuery>;
export type GetCurrentNetworkUserSuspenseQueryHookResult = ReturnType<typeof useGetCurrentNetworkUserSuspenseQuery>;
export type GetCurrentNetworkUserQueryResult = Apollo.QueryResult<GetCurrentNetworkUserQuery, GetCurrentNetworkUserQueryVariables>;
export const GetMyAvailableInvitesCountDocument = gql`
    query GetMyAvailableInvitesCount {
  myAvailableInvitesCount
}
    `;

/**
 * __useGetMyAvailableInvitesCountQuery__
 *
 * To run a query within a React component, call `useGetMyAvailableInvitesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAvailableInvitesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAvailableInvitesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAvailableInvitesCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>(GetMyAvailableInvitesCountDocument, options);
      }
export function useGetMyAvailableInvitesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>(GetMyAvailableInvitesCountDocument, options);
        }
export function useGetMyAvailableInvitesCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>(GetMyAvailableInvitesCountDocument, options);
        }
export type GetMyAvailableInvitesCountQueryHookResult = ReturnType<typeof useGetMyAvailableInvitesCountQuery>;
export type GetMyAvailableInvitesCountLazyQueryHookResult = ReturnType<typeof useGetMyAvailableInvitesCountLazyQuery>;
export type GetMyAvailableInvitesCountSuspenseQueryHookResult = ReturnType<typeof useGetMyAvailableInvitesCountSuspenseQuery>;
export type GetMyAvailableInvitesCountQueryResult = Apollo.QueryResult<GetMyAvailableInvitesCountQuery, GetMyAvailableInvitesCountQueryVariables>;
export const FinishProfilePlatformSocialDocument = gql`
    mutation FinishProfilePlatformSocial($input: FinishProfilePlatformSocialInput!) {
  finishProfilePlatformSocial(input: $input) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
export type FinishProfilePlatformSocialMutationFn = Apollo.MutationFunction<FinishProfilePlatformSocialMutation, FinishProfilePlatformSocialMutationVariables>;

/**
 * __useFinishProfilePlatformSocialMutation__
 *
 * To run a mutation, you first call `useFinishProfilePlatformSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProfilePlatformSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProfilePlatformSocialMutation, { data, loading, error }] = useFinishProfilePlatformSocialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishProfilePlatformSocialMutation(baseOptions?: Apollo.MutationHookOptions<FinishProfilePlatformSocialMutation, FinishProfilePlatformSocialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishProfilePlatformSocialMutation, FinishProfilePlatformSocialMutationVariables>(FinishProfilePlatformSocialDocument, options);
      }
export type FinishProfilePlatformSocialMutationHookResult = ReturnType<typeof useFinishProfilePlatformSocialMutation>;
export type FinishProfilePlatformSocialMutationResult = Apollo.MutationResult<FinishProfilePlatformSocialMutation>;
export type FinishProfilePlatformSocialMutationOptions = Apollo.BaseMutationOptions<FinishProfilePlatformSocialMutation, FinishProfilePlatformSocialMutationVariables>;
export const FinishProfilePlatformWalletDocument = gql`
    mutation FinishProfilePlatformWallet($input: FinishProfilePlatformWalletInput!) {
  finishProfilePlatformWallet(input: $input) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
export type FinishProfilePlatformWalletMutationFn = Apollo.MutationFunction<FinishProfilePlatformWalletMutation, FinishProfilePlatformWalletMutationVariables>;

/**
 * __useFinishProfilePlatformWalletMutation__
 *
 * To run a mutation, you first call `useFinishProfilePlatformWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProfilePlatformWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProfilePlatformWalletMutation, { data, loading, error }] = useFinishProfilePlatformWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishProfilePlatformWalletMutation(baseOptions?: Apollo.MutationHookOptions<FinishProfilePlatformWalletMutation, FinishProfilePlatformWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishProfilePlatformWalletMutation, FinishProfilePlatformWalletMutationVariables>(FinishProfilePlatformWalletDocument, options);
      }
export type FinishProfilePlatformWalletMutationHookResult = ReturnType<typeof useFinishProfilePlatformWalletMutation>;
export type FinishProfilePlatformWalletMutationResult = Apollo.MutationResult<FinishProfilePlatformWalletMutation>;
export type FinishProfilePlatformWalletMutationOptions = Apollo.BaseMutationOptions<FinishProfilePlatformWalletMutation, FinishProfilePlatformWalletMutationVariables>;
export const UpdateProfilePlatformDocument = gql`
    mutation UpdateProfilePlatform($input: UpdateProfilePlatformInput!) {
  updateProfilePlatform(input: $input) {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;
export type UpdateProfilePlatformMutationFn = Apollo.MutationFunction<UpdateProfilePlatformMutation, UpdateProfilePlatformMutationVariables>;

/**
 * __useUpdateProfilePlatformMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePlatformMutation, { data, loading, error }] = useUpdateProfilePlatformMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfilePlatformMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePlatformMutation, UpdateProfilePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePlatformMutation, UpdateProfilePlatformMutationVariables>(UpdateProfilePlatformDocument, options);
      }
export type UpdateProfilePlatformMutationHookResult = ReturnType<typeof useUpdateProfilePlatformMutation>;
export type UpdateProfilePlatformMutationResult = Apollo.MutationResult<UpdateProfilePlatformMutation>;
export type UpdateProfilePlatformMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePlatformMutation, UpdateProfilePlatformMutationVariables>;
export const GetRoomsForPlatformDocument = gql`
    query GetRoomsForPlatform {
  rooms {
    ...RoomDetail
  }
}
    ${RoomDetailFragmentDoc}`;

/**
 * __useGetRoomsForPlatformQuery__
 *
 * To run a query within a React component, call `useGetRoomsForPlatformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsForPlatformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsForPlatformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoomsForPlatformQuery(baseOptions?: Apollo.QueryHookOptions<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>(GetRoomsForPlatformDocument, options);
      }
export function useGetRoomsForPlatformLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>(GetRoomsForPlatformDocument, options);
        }
export function useGetRoomsForPlatformSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>(GetRoomsForPlatformDocument, options);
        }
export type GetRoomsForPlatformQueryHookResult = ReturnType<typeof useGetRoomsForPlatformQuery>;
export type GetRoomsForPlatformLazyQueryHookResult = ReturnType<typeof useGetRoomsForPlatformLazyQuery>;
export type GetRoomsForPlatformSuspenseQueryHookResult = ReturnType<typeof useGetRoomsForPlatformSuspenseQuery>;
export type GetRoomsForPlatformQueryResult = Apollo.QueryResult<GetRoomsForPlatformQuery, GetRoomsForPlatformQueryVariables>;
export const GetRoomDeeplinkDocument = gql`
    query GetRoomDeeplink($roomId: String!) {
  roomDeeplink(roomId: $roomId) {
    url
  }
}
    `;

/**
 * __useGetRoomDeeplinkQuery__
 *
 * To run a query within a React component, call `useGetRoomDeeplinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomDeeplinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomDeeplinkQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetRoomDeeplinkQuery(baseOptions: Apollo.QueryHookOptions<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>(GetRoomDeeplinkDocument, options);
      }
export function useGetRoomDeeplinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>(GetRoomDeeplinkDocument, options);
        }
export function useGetRoomDeeplinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>(GetRoomDeeplinkDocument, options);
        }
export type GetRoomDeeplinkQueryHookResult = ReturnType<typeof useGetRoomDeeplinkQuery>;
export type GetRoomDeeplinkLazyQueryHookResult = ReturnType<typeof useGetRoomDeeplinkLazyQuery>;
export type GetRoomDeeplinkSuspenseQueryHookResult = ReturnType<typeof useGetRoomDeeplinkSuspenseQuery>;
export type GetRoomDeeplinkQueryResult = Apollo.QueryResult<GetRoomDeeplinkQuery, GetRoomDeeplinkQueryVariables>;
export const GetUserMeDocument = gql`
    query GetUserMe {
  userMe {
    ...UserMe
  }
}
    ${UserMeFragmentDoc}`;

/**
 * __useGetUserMeQuery__
 *
 * To run a query within a React component, call `useGetUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserMeQuery(baseOptions?: Apollo.QueryHookOptions<GetUserMeQuery, GetUserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMeQuery, GetUserMeQueryVariables>(GetUserMeDocument, options);
      }
export function useGetUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMeQuery, GetUserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMeQuery, GetUserMeQueryVariables>(GetUserMeDocument, options);
        }
export function useGetUserMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserMeQuery, GetUserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserMeQuery, GetUserMeQueryVariables>(GetUserMeDocument, options);
        }
export type GetUserMeQueryHookResult = ReturnType<typeof useGetUserMeQuery>;
export type GetUserMeLazyQueryHookResult = ReturnType<typeof useGetUserMeLazyQuery>;
export type GetUserMeSuspenseQueryHookResult = ReturnType<typeof useGetUserMeSuspenseQuery>;
export type GetUserMeQueryResult = Apollo.QueryResult<GetUserMeQuery, GetUserMeQueryVariables>;
export const GetLoginDeeplinkDocument = gql`
    query GetLoginDeeplink {
  loginDeeplink {
    url
  }
}
    `;

/**
 * __useGetLoginDeeplinkQuery__
 *
 * To run a query within a React component, call `useGetLoginDeeplinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginDeeplinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginDeeplinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoginDeeplinkQuery(baseOptions?: Apollo.QueryHookOptions<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>(GetLoginDeeplinkDocument, options);
      }
export function useGetLoginDeeplinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>(GetLoginDeeplinkDocument, options);
        }
export function useGetLoginDeeplinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>(GetLoginDeeplinkDocument, options);
        }
export type GetLoginDeeplinkQueryHookResult = ReturnType<typeof useGetLoginDeeplinkQuery>;
export type GetLoginDeeplinkLazyQueryHookResult = ReturnType<typeof useGetLoginDeeplinkLazyQuery>;
export type GetLoginDeeplinkSuspenseQueryHookResult = ReturnType<typeof useGetLoginDeeplinkSuspenseQuery>;
export type GetLoginDeeplinkQueryResult = Apollo.QueryResult<GetLoginDeeplinkQuery, GetLoginDeeplinkQueryVariables>;
export const CreateWaitlistUserDocument = gql`
    mutation CreateWaitlistUser($input: CreateWaitlistUserInput!) {
  createWaitlistUser(input: $input) {
    email
    locationUserInput
    createdAt
  }
}
    `;
export type CreateWaitlistUserMutationFn = Apollo.MutationFunction<CreateWaitlistUserMutation, CreateWaitlistUserMutationVariables>;

/**
 * __useCreateWaitlistUserMutation__
 *
 * To run a mutation, you first call `useCreateWaitlistUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitlistUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitlistUserMutation, { data, loading, error }] = useCreateWaitlistUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWaitlistUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateWaitlistUserMutation, CreateWaitlistUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWaitlistUserMutation, CreateWaitlistUserMutationVariables>(CreateWaitlistUserDocument, options);
      }
export type CreateWaitlistUserMutationHookResult = ReturnType<typeof useCreateWaitlistUserMutation>;
export type CreateWaitlistUserMutationResult = Apollo.MutationResult<CreateWaitlistUserMutation>;
export type CreateWaitlistUserMutationOptions = Apollo.BaseMutationOptions<CreateWaitlistUserMutation, CreateWaitlistUserMutationVariables>;