import { Control, FieldValues, Path } from 'react-hook-form'
import { cn } from 'lib/utils'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input, InputProps } from 'components/ui/input'

interface CommonFormInputFieldProps<SchemaType extends FieldValues> extends InputProps {
  name: Path<SchemaType>
  control: Control<SchemaType>
  label: string
  required?: boolean
  hasErrorLabel?: boolean
}

export const CommonFormInputField = <SchemaType extends FieldValues>({
  name,
  control,
  label,
  required = false,
  hasErrorLabel = true,
  className,
  ...props
}: CommonFormInputFieldProps<SchemaType>) => (
  <FormField
    required={required}
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormItem>
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <>
            <Input
              {...field}
              type="text"
              className={cn(
                'w-[300px] rounded-full border-none bg-secondary-transparent30 px-[20px] text-base font-[300] text-white disabled:text-white-transparent60 placeholder:text-white-transparent60',
                className,
              )}
              {...props}
            />
            {hasErrorLabel && fieldState.error && (
              <FormMessage className="w-[300px]">{fieldState.error.message}</FormMessage>
            )}
          </>
        </FormControl>
      </FormItem>
    )}
  />
)
