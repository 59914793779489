import { cn } from 'lib/utils'
import { InfoCircleIcon } from 'components/icons'

interface InfoBannerProps {
  children: React.ReactNode
  classNames?: string
}

const InfoBanner = ({ children, classNames }: InfoBannerProps) => (
  <div
    className={cn(
      'flex bg-white-transparent10 px-4 py-3 gap-4 border-[1px] border-white-transparent10 rounded-[1rem]',
      classNames,
    )}
  >
    <div>
      <InfoCircleIcon className="size-5" />
    </div>
    <div>{children}</div>
  </div>
)

export default InfoBanner
